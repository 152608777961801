<!--
* @Author: tianzl
* @Date: 2023-12-05 13:46:28
* @LastEditors: tianzl
* @LastEditTime: 2023-12-05 13:46:28
* @Description: 线索培育-培育跟进
 -->
<template>
  <div class="follow">
    <van-collapse v-model="activeNames" style="padding: 16px;" :right-icon="false">
      <van-collapse-item class="form-collapse-item special-collapse-item" disabled name="1">
        <template #title class="">
          <div>{{ $t('跟进信息') }}</div>
          <!-- <div class="icon" @click="improveUser">
            <van-icon name="edit" size="13px" color="#0040C5"/>
            完善线索信息
          </div> -->
        </template>
        <van-form ref="followInfo" input-align="right" error-message-align="right" class="followInfo">
          <!-- 跟进方式 -->
          <van-field
            name="radio"
            label="跟进方式"
            required
            input-align="right"
            error-message-align="right"
            :rules="[{required:true, message:'请选择跟进方式'}]">
            <template #input>
              <van-radio-group
                v-model="followRequest.followMode"
                icon-size="32px"
                class="custom-radio-group-wrap"
                >
                <van-radio v-for="item in followModeList" :key="item.code"  :name="item.code" class="custom-radio-wrap">
                  <template #icon="props">
                    <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
                  </template>
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <!-- 回访结果 无效跟进时展示 -->
          <van-field
            v-if="['1000016'].includes(clueDetail.hatchState)"
            name="radio"
            :label="$t('回访结果')"
            required
            input-align="right"
            error-message-align="right"
            :rules="[{required:true, message:$t('请选择回访结果')}]">
            <template #input>
              <van-radio-group
                v-model="followRequest.visitResult"
                icon-size="32px"
                class="custom-radio-group-wrap"
                >
                <van-radio v-for="item in visitResultList" :key="item.code"  :name="item.code" class="custom-radio-wrap">
                  <template #icon="props">
                    <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
                  </template>
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <template v-if="!isKeepInvalid">
            <!-- 跟进结果 1000012已分配到店16无效18已到店 -->
            <van-field
              v-if="!['1000012', '1000018'].includes(clueDetail.hatchState) && !invalidVisitResult"
              name="radio"
              label="跟进结果"
              required
              :rules="[{required: !followRequest.followResult,message:'请选择跟进结果'}]"
              error-message-align="right"
              input-align="right">
              <template #input>
                <van-radio-group
                  v-model="followRequest.followResult"
                  icon-size="32px"
                  class="custom-radio-group-wrap"
                >
                  <van-radio v-for="item in followUpResultList" :key="item.code" :name="item.code" class="custom-radio-wrap">
                    <template #icon="props">
                      <div class="custom-radio" :class="{ checked: props.checked, commonCheck: props.checked }" @click.stop="onChangeResult(item)">{{item.name}}</div>
                    </template>
                  </van-radio>
                  <p v-if="followRequest.followResult == '1011015'" style="font-size: 12px;color: #eed484;margin-left: 12px;">{{ $t('注：新线索，联系客户未接通时选择') }}</p>
                </van-radio-group>
              </template>
            </van-field>
            <!-- 跟进结果为客户预约时展示 -->
            <template v-if="followRequest.followResult==='1011019'">
              <!-- 预约事项 -->
              <van-field
                name="radio"
                label="预约事项"
                required
                :rules="[{required: followRequest.appointment.appointmentTypeCode ? false : true,message:'请选择预约事项'}]"
                error-message-align="right"
                input-align="right">
                <template #input>
                  <van-radio-group
                    v-model="followRequest.appointment.appointmentTypeCode"
                    icon-size="32px"
                    class="custom-radio-group-wrap">
                    <van-radio v-for="item in appointmentTypeList" :key="item.dictCode" :name="item.dictCode" class="custom-radio-wrap">
                      <template #icon="props">
                        <!-- @click.stop="onChangeAppoinment(item)" -->
                        <div class="custom-radio" :class="{ checked: props.checked, appointmentTypeCheck: props.checked }">{{item.dictName}}</div>
                      </template>
                    </van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <!-- 预约时间 -->
              <van-field
                :value="followRequest.appointment.appointmentStartTime | formatAppointmentTime"
                required
                :label="$t('预约时间')"
                :placeholder="$t('请选择')"
                :right-icon="'arrow'"
                readonly
                input-align="right"
                :rules="[{required:true}]"
                @click="onSelectAppointmentTime">
              </van-field>
            </template>
            <!-- 跟进结果为分配到店或客户预约时展示 分配门店 -->
            <template v-if="['1011017','1011019'].includes(followRequest.followResult)">
                <van-field            
                  v-model="followRequest.assignDealerName"
                  required
                  label="分配门店"
                  placeholder="请选择分配门店"
                  :right-icon="'arrow'"
                  readonly
                  input-align="right"
                  :rules="[{required:true}]"
                  @click="onSelectDealer">
                </van-field>
                <van-field     
                  v-if="followRequest.assignDealerName"       
                  v-model="followRequest.dealerAddress"
                  required
                  label="门店地址"
                  readonly
                  input-align="right"
                  >
                </van-field>
              </template>
            <!-- 跟进结果为待跟进时展示 联系结果 -->
            <van-field
              v-if="followRequest.followResult==='1011015'"
              v-model="followRequest.sysRemark"
              required
              label="联系结果"
              placeholder="请选择联系结果"
              :right-icon="'arrow'"
              readonly
              input-align="right"
              :rules="[{required:true}]"
              @click="selectField('failCode',1015,false)">
            </van-field>
            <!-- 跟进结果为无效时展示 无效原因 -->
            <van-field
              v-if="followRequest.followResult==='1011018'"
              v-model="followRequest.sysRemark"
              required
              :label="$t('无效原因')"
              :placeholder="$t('请选择')"
              :right-icon="'arrow'"
              readonly
              input-align="right"
              :rules="[{required:true}]"
              @click="selectField('failCode','invalidReason',false)">
            </van-field>
            <!-- 跟进结果为战败时展示 战败原因 -->
            <van-field
              v-if="followRequest.followResult==='1011020'"
              v-model="followRequest.sysRemark"
              required
              label="战败原因"
              placeholder="请选择战败原因"
              :right-icon="'arrow'"
              readonly
              input-align="right"
              :rules="[{required:true}]"
              @click="selectField('failCode','defeatReason',false)">
            </van-field>
            <!-- 战败原因补充 -->
            <van-field
              v-if="supplement"
              v-model="followRequest.sysRemarkSupplement"
              :label="$t('战败原因补充')"
              :placeholder="$t('请输入战败补充原因')"
              input-align="right"
              maxlength="10"
              :required="supplementRequired"
              :rules="supplementRequired ? [{required: true}] : []">
            </van-field>
            <!-- 跟进结果为持续培育、分配到店、客户预约时展示性别字段 必填 -->
            <van-field
              v-if="['1011016', '1011017', '1011019'].includes(followRequest.followResult)"
              name="radio"
              :label="$t('性别')"
              required
              input-align="right"
              error-message-align="right"
              :rules="[{required:true, message:$t('请选择性别')}]">
              <template #input>
                <van-radio-group
                  v-model="followRequest.userSex"
                  icon-size="32px"
                  class="custom-radio-group-wrap">
                  <van-radio v-for="item in userSexList" :key="item.code" :name="item.code" class="custom-radio-wrap">
                    <template #icon="props">
                      <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
                    </template>
                  </van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <!-- 意向信息 预约试驾时必填 -->
            <!-- 跟进结果为无效、待跟进或者战败时  不显示车系车型 -->
            <template v-if="!['1011018', '1011020', '1011015'].includes(followRequest.followResult) && !['1000012', '1000018', '1000017'].includes(clueDetail.hatchState)">
              <van-field
                ref="carInfo"
                :value="followRequest | formatCarInfo"
                required
                label="意向车系车型"
                placeholder="请选择车系车型"
                :right-icon="'arrow'"
                readonly
                input-align="right"
                :rules="[{ required: true, trigger: 'change' }]"
                @click="selectCarModel">
              </van-field>
            </template>
          </template>
          <!-- 跟进记录 -->
          <van-cell title="跟进记录" required>
            <template #right-icon>
              <btn-voice-input @input="(value) => (followRequest.followRemark = followRequest.followRemark + value.slice(0, 200))" />
            </template>
          </van-cell>
          <van-field
            v-model.trim="followRequest.followRemark"
            rows="3"
            autosize
            type="textarea"
            maxlength="200"
            placeholder="请输入不少于10个字的跟进记录"
            show-word-limit
            :error-message="followRemarkErrMsg"
            :rules="[{required:true}, { validator: followRemarkValidator }]"
          />
          <!-- 下次跟进时间 -->
          <van-field
            v-if="['1011015', '1011016', '1011035', '1011037', '1011039'].includes(followRequest.followResult) && !isKeepInvalid"
            :value="followRequest.planTime | formatPlanTime"
            required
            :label="$t('下次跟进时间')"
            :placeholder="$t('请选择下次跟进时间')"
            right-icon="arrow"
            readonly
            input-align="right"
            :rules="[{required:true}]"
            @click="nextFollowTime">
          </van-field>
        </van-form>
      </van-collapse-item>
    </van-collapse>
    <div class="common-footer">
      <van-button class="btn back" @click="goBack">返回</van-button>
      <van-button class="btn submit" @click="onSubmit">提交</van-button>
    </div>
    <!-- <van-calendar v-model="showNextFollowTime" :title="$t('下次跟进时间')" 
      :show-confirm="false"
      :first-day-of-week="1"
      :min-date="planeMinDate" 
      :max-date="planeMaxDate" 
      :default-date="defaultValue" 
      @confirm="dateConfirm"/> -->
    <CityDealers :value="isShowDealers" :leadId="$route.query.id" :followResult="followRequest.followResult" @submit="handleSelectChange" @cancel="isShowDealers = false"/>
  </div>
</template>
<script>
import clueServices from '@/services/clueServices'
import { dateFormat } from '@/utils'
import BtnVoiceInput from '@/components/btn-voice-input'
import dayjs from 'dayjs'
import baseDataServices from '@/services/baseDataServices'
import commonSelector from '@/components/common-selector'
import selectDate from '@/components/date-picker'
import selectCar from '@/components/select-car'
import CityDealers from '@/modules/clue-incubation/components/city-dealers.vue'
import moment from 'moment'
import selectDefeatReasons from '@/components/select-defeat-reasons'
import { pick } from 'lodash'

const enumeration = {
  // else: ['1011016', '1011017', '1011019'],
  // 1000010: ['1011016', '1011017', '1011019', '1011020'], // 培育中可选
  // 1000005: [ '1011016', '1011017', '1011019'], // 首次培育
  // 1000011: [ '1011016', '1011017', '1011019'], // 待跟进
  // 1000016: ['1011039', '1011038'], // 无效
  // 1000017: ['1011037', '1011036'] // 战败
  else: ['1011016', '1011017', '1011019'],
  0: ['1011018', '1011015', '1011016', '1011017', '1011019'],
  1: ['1011016', '1011017', '1011019', '1011020'],
  2: ['1011039', '1011038'],
  1000016: ['1011016', '1011017', '1011019'], // 无效
  1000017: ['1011037', '1011036'] // 战败
}
export default {
  name: 'clueFollow',
  components: {
    BtnVoiceInput,CityDealers
  },
  filters:{
    formatCarInfo(info) {
      const { seriesName, modelName } = info
      let display = ''
      seriesName && (display += ` ${seriesName}`)
      modelName && (display += ` ${modelName}`)
      return display
    },
    formatAppointmentTime(appointmentStartTime){
      if (!appointmentStartTime) return ''
      return moment(appointmentStartTime).format('MM-DD HH:mm')
    },
    formatPlanTime(planTime){
      if (!planTime) return ''
      return moment(planTime).format('YYYY-MM-DD HH:mm')
    }
  },
  data() {
    return {
      id: '', // 线索id
      activeNames: ['1'],
      clueDetail: {},
      followResult: '',
      followRequest:{
        isCheckDealer: false,
        visitResult: '', // 回访结果
        followMode: '1010002', // 跟进方式
        followResult: '',// 跟进结果
        appointment:{
          appointmentTypeCode: '', // 预约事项 预约试驾:2001001、预约到店:2001002
          // typeCode: '2002001', // 默认是到店试驾
          appointmentStartTime: '',// 预约时间
        },
        dealerCode: '', // 分配门店
        assignDealerId: '', // 分配门店
        assignDealerName:'',// 分配门店
        // invalidReason:'', // 无效原因
        // defeatReason:'', // 战败原因
        followRemark:'', // 跟进记录
        planTime: moment().add(15, 'days').format('YYYY-MM-DD HH:mm:ss'), // 下次跟进时间,年月日+'23:59:59'
        modelCode:'',
        modelName:'',
        seriesCode:'',
        seriesName:'',
        userMobile:'',
        failCode: '',
        sysRemark: '',
        sysRemarkSupplement: '', // 战败原因补充
        id: '',
        dealerAddress:'',
        userSex: '' // 性别
      },
      // 预约类型
      appointmentTypeList:[
        { dictCode: '2001002', dictName: this.$t('预约到店') },
        { dictCode: '2001001', dictName: this.$t('预约试驾') },
      ],
      // showNextFollowTime: false,
      defaultValue: null,
      planeMinDate: new Date(),
      planeMaxDate: new Date(dayjs().add(60, 'days')),
      isShowDealers: false,
      hatchState: '',
      validStatus: 0,
      followRemarkErrMsg: '',
      supplement: false, // 战败原因补充
      supplementRequired: false, // 战败原因补充是否必填
    }
  },
  computed:{
    // 跟进方式
    followModeList() {
      let list = this.$store.getters.getDictHash(1010)
      // 排除店内接待
      list = list.filter(({ code })=> !(['1010003'].includes(code)))
      return list
    },
    // 跟进结果
    /*
     * 迭代15调整-根据线索有效性validStatus("有效性 0 未知 1有效 2无效")展示跟进结果
     * 线索有效性与培育状态对应关系
     * 0:总部待分配/新分配/待跟进/无效审核中
     * 1:培育中/已分配到店/已到店/战败审核中/战败
     * 2:无效
     * 结果：
     * 0待定:无效/待跟进/持续培育/分配到店/客户预约
     * 1有效:持续培育/分配到店/客户预约/战败
     * */
    followUpResultList() {
      // let list = this.$store.getters.getDictHash(1011) || []
      // list = list.filter(({ code }) => (enumeration[this.clueDetail?.hatchState || 'else'] || enumeration.else).includes(code))
      // return list
      let list = this.$store.getters.getDictHash(1011) || []
      if (this.clueDetail?.hatchState === '1000016') {
        list = list.filter((item) => enumeration[1000016].includes(item.code))
      } else if (this.validStatus === 1 && this.clueDetail?.hatchState === '1000017') {
        list = list.filter((item) => enumeration[1000017].includes(item.code))
      } else {
        list = list.filter((item) => (enumeration[this.validStatus] || enumeration.else).includes(item.code))
      }
      // 客户预约在无效之前
      if (this.validStatus === 0) {
        list.splice(4, 1, ...list.splice(3, 1, list[4]))
      }
      return list
    },
    // followUpResultList() {
    //   const list = [
    //     { dictName:'持续培育',dictCode:'1011016' },
    //     { dictName:'分配到店',dictCode:'1011017' },
    //     { dictName:'客户预约',dictCode:'1011019' },
    //     // { dictName:'待跟进',dictCode:'1011015' }, // 首次培育或线索为待跟进时可以选
    //     // { dictName:'无效',dictCode:'1011018' }, // 首次培育可选
    //     // { dictName:'战败',dictCode:'1011020' }, //培育中可选
    //   ]
    //   const tobeFollow = { dictName:'待跟进',dictCode:'1011015' }
    //   const invalid = { dictName:'无效',dictCode:'1011018' }
    //   const defeat = { dictName:'战败',dictCode:'1011020' }
    //   // 培育中可选
    //   if (this.hatchState === '1000010'){
    //     list.push(defeat)
    //   }
    //   // 首次培育或线索为待跟进时可以选
    //   if (['1000005','1000011'].includes(this.hatchState)){
    //     list.push(tobeFollow)
    //   }
    //   // 首次培育可选
    //   if (this.hatchState === '1000005'){
    //     list.push(invalid)
    //   }
    //   return list
    // },
    userSexList() {
      return [
        { name: this.$t('男'), code: '1' },
        { name: this.$t('女'), code: '2' }
      ]
    },
    // 回访结果list
    visitResultList() {
      let list = this.$store.getters.getDictHash(1019)
      return list.filter(({ code }) => ['1019003','1019004'].includes(code))
    },
    // 线索无效 且 还未选择回访结果
    invalidVisitResult() {
      const { state } = this.clueDetail
      return state === '1000016' && !this.followRequest.visitResult
    },
    // 回访结果是否是保持无效
    isKeepInvalid() {
      return this.followRequest.visitResult === '1019003'
    }
  },
  watch: {
    'followRequest.followResult':{
      handler(val){
        this.supplement = false
        this.supplementRequired = false
        this.followRequest.sysRemarkSupplement = ''
        if (val === '1011001') {
          this.followRequest.userSex = ''
          this.followRequest.seriesCode = ''
          this.followRequest.seriesName = ''
          this.followRequest.modelCode = ''
          this.followRequest.modelName = ''
          this.followRequest.tryDriveLevel = ''
          this.followRequest.tryDriveLevelName = ''
          this.followRequest.carInfo = {}
        }
        console.log('followRequest.followResult:',val)
      },
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getClueDetail()
  },
  methods: {
    // 获取详情
    async getClueDetail() {
      this.clueDetail = await clueServices.getHatchClueDetail({ id: this.id })
      const { userMobile, userSex = '', hatchState, validStatus, seriesCode, seriesName, modelCode, modelName } = this.clueDetail
      this.followRequest.userMobile = userMobile
      this.followRequest.userSex = userSex ? String(userSex) : '' 
      const carInfo = {
        modelCode,
        modelName,
        seriesCode,
        seriesName
      }
      this.followRequest = Object.assign(this.followRequest, carInfo)
      this.hatchState = hatchState
      this.validStatus = validStatus
    },
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    // 完善线索信息
    improveUser() {
      this.$router.push({
        path:'/clue-incubation/edit',
        query:{
          id: this.id,
          operate: 'edit',
        }
      })
    },
    // 提交
    async onSubmit() {
      // 输入内容校验
      try {
        await this.$refs.followInfo.validate()
        this.followRequest.id = this.id
        console.log('this.followRequest:',this.followRequest)
        if (this.followRequest.appointment.appointmentTypeCode==='2001001'){
          this.followRequest.appointment.typeCode = '2002001'
        } else {
          this.followRequest.appointment.typeCode = ''
        }
        // 线索已经分配到门店， 或状态为已到店,传跟进结果线索培育
        if (['1000012', '1000018'].includes(this.clueDetail.hatchState)) {
          this.followRequest.followResult = '1011040'
        }
        let followRequestParams = JSON.parse(JSON.stringify(this.followRequest))
        if (this.supplement && followRequestParams.sysRemarkSupplement) {
          followRequestParams.sysRemark = followRequestParams.sysRemark + '/' + followRequestParams.sysRemarkSupplement
        }
        let submitParams = {}
        if (followRequestParams.visitResult === '1019003') {
          submitParams = pick(followRequestParams, ['followMode', 'visitResult', 'followRemark', 'id'])
        } else {
          submitParams = followRequestParams
        }
        const res = await clueServices.followClue(submitParams)
        this.$router.go(-1)
        // const { code='', msg='' } = res
        // if (['30033','30034'].includes(code)) {
        //   this.$dialog.confirm({
        //     title: '',
        //     message: msg,
        //     confirmButtonColor: '#B9921A',
        //     confirmButtonText: this.$t('继续激活'),
        //   }).then(async () => {
        //     await clueServices.followClue({ ...submitParams, forceInvalidActive: true })
        //     this.$router.go(-1)
        //   })
        // } else {
        //   this.$router.go(-1)
        // }
      } catch (error) {
        console.log('clue incubation follow:',error)
      }
    },
    // 改变跟进结果
    onChangeResult({ code, name }) {
      this.followRequest.failCode = ''
      this.followRequest.sysRemark = ''
      this.followRequest.followResult = code
    },
    nextFollowTime() {
      const params = {
        type:'datetime',
        minDate: this.planeMinDate,
        maxDate: this.planeMaxDate,
        filter: this.filter,
        date: this.followRequest.planTime,
      }
      selectDate(params).then(res=>{
        console.log('selectDate res:',moment(res).format('YYYY-MM-DD HH:mm:ss'))
        this.followRequest.planTime=moment(res).format('YYYY-MM-DD HH:mm:ss')
      })
    },
    // 选择预约时间
    onSelectAppointmentTime(){
      const params = {
        type:'datetime',
        minDate: new Date(),
        filter(type, options) {
          if (type === 'minute') {
            return options.filter((option) => option % 30 === 0)
          }
          return options
        },
      }
      selectDate(params).then(res=>{
        console.log('selectDate res:',moment(res).format('YYYY-MM-DD HH:mm:ss'))
        this.followRequest.appointment.appointmentStartTime=moment(res).format('YYYY-MM-DD HH:mm:ss')
      })
    },
    // 下次跟进时间
    // dateConfirm(date) {
    //   this.followRequest.planTime = dateFormat(date,'YYYY-MM-DD')+' 23:59:59'
    //   this.showNextFollowTime = false
    // },
    // 预约时间间隔10分钟
    filter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 10 === 0)
      }
      return options
    },
    // 选择战败原因
    onSelectDefeatReasons(field) {
      selectDefeatReasons({ bizType: 2 }).then((data) => {
        this.followRequest[field] = ''
        this.followRequest.sysRemark = ''
        const reasonObj = data[data.length-1] || {}
        const { id, reasonType, requireFlag } = reasonObj
        this.followRequest[field] = id
        this.followRequest.sysRemark = data.map((k) => k.reason).join('/')
        if (reasonType === 1) {
          this.supplement = false
          this.supplementRequired = false
        } else {
          this.supplement = true
          this.supplementRequired = requireFlag === 1
        }
      })
    },
    // 无效/战败/联系结果
    async selectField(field, dictType, multiple = false) {
      if (dictType === 'defeatReason') {
        this.onSelectDefeatReasons(field)
        return false
      }
      let reasonList = []
      if (['invalidReason', 'defeatReason'].includes(dictType)) {
        const requestUrl = dictType === 'invalidReason' ? 'getInvalidReasonList' : 'getDefeatReasonList'
        const result = await baseDataServices[requestUrl]({ pageNum: 1, pageSize: 9999 })
        reasonList = result || []
      }
      // 无效战败原因
      const _obj = {
        field,
        dictType,
        multiple,
        reasonList,
        lastSelect: this.followRequest[field]
      }
      commonSelector(_obj).then(res => {
        this.followRequest[field] = res.dictCode
        this.followRequest.sysRemark = res.dictName
      })
    },
    // 选择意向信息
    selectCarModel() {
      let initCarInfo = {
        seriesCode: '',
        modelCode:''
      }
      selectCar({ initCarInfo })
        .then(carInfo => {
          if (typeof carInfo.modelCode === 'undefined') {
            this.$toast('请选择正确的车系车型')
            return
          }
          this.followRequest.modelCode = carInfo.modelCode
          this.followRequest.modelName = carInfo.modelName
          this.followRequest.seriesName = carInfo.seriesName
          this.followRequest.seriesCode = carInfo.seriesCode
        })
        .catch(() => { })
    },
    // 选择门店
    onSelectDealer(){
      this.isShowDealers = true
    },
    handleSelectChange(dealers=[]){
      if (dealers.length){
        const { name,id,code,address } = dealers[0]
        this.followRequest.assignDealerName = name
        this.followRequest.assignDealerId = id
        this.followRequest.dealerCode = code
        this.followRequest.dealerAddress = address
      }
      this.isShowDealers = false
    },
    followRemarkValidator(value) {
      if (value.length < 10) {
        this.followRemarkErrMsg = this.$t('请输入不少于10个字的跟进记录')
        return false
      }
      this.followRemarkErrMsg = ''
      return true
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../clue/style/commonless.less";
.follow{
  padding-bottom: 96px;
  /deep/ .special-collapse-item{
    .van-collapse-item__title--disabled{
      .van-icon-arrow{
        display: none;
      }
    }
    .van-cell__title{
      display: flex;
      .icon{
        flex: 1;
        cursor: pointer;
        text-align: right;
        margin-left: 10px;
        font-size: 14px;
        color: #0040C5;
      }
    }
  }

  /deep/ .custom-radio-group-wrap {
    margin-top: -10px;
    display: flex;
    flex-wrap: wrap;
    .custom-radio-wrap {
      margin-top: 10px;
      margin-left: 12px;
      .custom-radio {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        height: 24px;
        color: rgba(13,23,26,0.45);
        background-color: #F5F5F5;
        border-radius: 3px;
        font-size: 12px;
        // &.checked {
        //   // border: 1px solid @yellow;
        //   // color: @yellow-text
        // }
        &.wechatCheck{
          color: #0D171A;
          background: #EED484;
        }
        &.appointmentTypeCheck{
          color: #0D171A;
          background: #EED484;
        }
        &.typeCheck{
          color: #0D171A;
          background: #EED484;
        }
        &.commonCheck{
          color: #0D171A;
          background: #EED484;
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: #ebedf0;
          border-color: #c8c9cc
        }
      }
    }
  }
}
</style>
