<template>
  <van-popup 
    :value="value"
    position="right"
    :lazy-render='false'
    :style="{ minWidth: isPC ? '400px' : '335px', width: '80%', height: '100%',padding:'12px 16px','box-sizing'	:'border-box	' }"
    @click-overlay="onClose">
    <van-search v-model="name" clearable class="search" placeholder="请输入门店名称搜索" @search="getCityDealers" @clear="getCityDealers"/>
    <CommonIndexBar ref="commonIndexBarRef"
      :content="content"
      :isSelection="true"
      :multiple="false"       
      height='80vh'
      titleKey="name"
      @change="onChange"/>
    <div class="bottoms">
      <van-button round plain type="default"  class="btn" @click="handleReset">取消</van-button>
      <van-button round  type="primary" class="btn" @click="handleOK">确认</van-button>
    </div>
  </van-popup>
</template>
<script>
import CommonIndexBar from '@/components/v2/system/CommonIndexBar/index.vue'
import baseDataServices from '@/services/baseDataServices'
import clueServices from '@/services/clueServices'
export default {
  components:{ CommonIndexBar },
  props:{
    // 是否显示
    value: {
      type: Boolean,
      default: false,
    },
    // PC
    isPC: {
      type: Boolean,
      default: false,
    },
    // 关闭时是否提交
    submitWhenClose: {
      type: Boolean,
      default: false,
    },
    followResult: {
      type: String,
      default: '',
    },
    leadId: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      formData: [],
      content: {},
      name: ''
    }
  },
  created(){
    this.getCityDealers()
  },
  methods:{
    emitSubmit() {
      // 提交
      this.$emit('submit', this.formData)
    },
    onClose() {
      // 关闭
      if (this.submitWhenClose) {
        this.emitSubmit()
      }
      this.$emit('cancel', false)
    },
    async getCityDealers(){
      const params = { businessStatus: 10371002, isFictitious: 10041002, dealerType: 1 }
      if (this.name) params.name = this.name
      const res = await baseDataServices.cityDealersPost(params)
      this.content = res
    },
    handleReset(){
      this.$emit('cancel')
    },
    handleOK(){
      if (!this.formData.length) return this.$toast('请选择分配门店')
      this.$emit('submit',this.formData)
    },
    async onChange(val) {
      this.formData = val
      if (val && val.length) {
        const { code, name, id: assignDealerId } = val[0]
        const params = {
          assignDealerName: name,
          dealerCode: code,
          followResult: this.followResult,
          id: this.leadId,
          assignDealerId,
          isCheckDealer: true,
        }
        const res = await clueServices.followClue(params)
        if (res && res.id) {
          this.$dialog
            .confirm({
              message: `门店正在跟进该客户，跟进状态为${this.getDictNameByCode(
                2000,
                res.state
              )}`,
              confirmButtonColor: '#B9921A',
            })
            .catch(() => {
              this.$refs.commonIndexBarRef.reset()
              this.formData = []
            })
        }
      }
    },
    search(){
      
    }
  }
}
</script>
<style lang="less" scoped>
.bottoms {
    width: 95%;
    padding: 16px;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 101;
    background-color: #fff;
    border-top: 1px solid #f8f8f8;
    
    .btn {
      flex: 1;
      &:first-child {
        margin-right: 14px;
      }
    }
  }
</style>